export const ACTIONS = {
    NULL: '',
    UPDATE: 'Update',
    CREATE: 'Create',
    APPROVE_DRAFT: 'Approve'
}

export const METHODS = {
    NULL: '',
    SINGLE_UPLOAD: 'Single Upload',
    BULK_UPLOAD: 'Bulk Upload',
}

export const FIELDS = {
    ACTION: 'action',
    METHOD: 'method',
    CSV: 'csv',
    ITEM_SKU_ID: 'itemSkuID',
    ITEM_SEARCH_SELECTION: 'itemSearchSelection',
    CHECKED_MENU_MENU_PAGE: 'checkedMenuMenuPage'
}

export const ITEM_FIELDS = {
    NAME: 'name',
    SKU_ID: 'skuId',
    CATEGORY_NAME: 'categoryName',
    IS_PURCHASABLE: 'isPurchasable',
    IS_STORABLE: 'isStorable',
    IS_SELLABLE: 'isSellable',
    IS_RAW_MATERIAL: 'isRawMaterial',
    IS_OT_PRODUCTION: 'isOtProduction',
    SELLING_UNIT: 'sellingUnit',
    CONVERSION_TEMPLATES: 'conversionTemplates',
    REMOVED_CONVERSION_TEMPLATES: 'removedConversionTemplates',
    MAIN_CONVERSION_TEMPLATE_INDEX: 'mainConversionTemplateIndex',
    IS_ACTIVE: 'isActive',
    IS_TAXABLE_GOODS: 'isTaxableGoods',
    TAX_RATE: 'taxRate',
    IMAGE_URL: 'imageUrl',
    PRODUCT_IMAGES: 'productImages',
    LABEL: 'label',
    LABEL_IDS: 'labelIds',
    TAG_IDS: 'tagIds',
    IS_NEW: 'isNew',
    NEW_LABEL_START_DATE: 'newLabelStartDate',
    NEW_LABEL_END_DATE: 'newLabelEndDate',
    NOTES: 'notes',
    DESCRIPTION: 'description',
    INTERNAL_NAME: 'internalName',
    LENGTH: 'length',
    WIDTH: 'width',
    HEIGHT: 'height',
    WEIGHT: 'weight',
    APPROXIMATE_VOLUME: 'approximateVolume',
    EXACT_VOLUME: 'exactVolume',
    WAREHOUSE_TAG_ID: 'warehouseTagId',
    LABEL_GROUP_ID: 'labelGroupId',
    INTERNAL_NOTES: 'internalNotes',
    STORAGE_UNIT: 'storageUnit',
    SHELF_LIFE_IN_DAYS: 'shelfLifeInHour',
    BEST_BEFORE_DATE_TYPE: 'bestBeforeDateType',
    SPECIFICATIONS: 'specifications',
    DETAILS: 'details',
    FEATURED_BANNER: 'featuredBanner',
    GARANSI_BANNER: 'garansiBanner',
    SPECIAL_TYPE: 'specialType',
    BILL_OF_MATERIALS: 'billOfMaterials',
    WHITELISTED_WAREHOUSE_PLANOGRAM: 'whitelistedPlanograms',
    IS_DIFFERENT_PAGE_DIFFERENT_IMAGE: 'isDifferentPageDifferentImage',
    MAP_IMAGE_URL_PAGES: 'mapImageUrlPages',
    DAYS_OF_INVENTORY: 'daysOfInventory',
    DYNAMIC_IMAGE_PRODUCT_CARD_UPLOADED_FILE: 'dynamicImageProductCardUploadedFile',
    PDP_VIDEO_UPLOADED_FILE: 'pdpVideoUploadedFile',
    PRODUCT_CARD_MEDIA_URL: 'productCardMediaUrl',
    PDP_MEDIA_URL: 'pdpMediaUrl',
    EXPIRATION_DATE_TYPE: 'expirationDateType',
    IS_21_PLUS: 'isTwentyOnePlus',
    IS_WEB_ALLOW_21_PLUS: 'isWebAllowTwentyOnePlus',
    IS_IOS_ALLOW_21_PLUS: 'isIosAllowTwentyOnePlus',
    IS_ANDROID_ALLOW_21_PLUS: 'isAndroidAllowTwentyOnePlus',
    PRODUCT_RESTRICTION_PLATFORMS: 'productRestrictionPlatforms',
    PRODUCT_IMAGES_INTERNAL_SINGLE: 'productImageInternalSingle',
    PRODUCT_IMAGES_INTERNAL_BULK : 'productImageInternalBulk',
    PRODUCT_IMAGES_INTERNAL_BUNDLE : 'productImageInternalBundle', 
    PRODUCT_IMAGES_INTERNAL_DELETED : 'productImageInternalDeleted',
    PRODUCT_IMAGES_INTERNAL_GROUPED: 'productImageInternalDTO',
    PACKAGING_TYPE: 'packagingType',
    STORAGE_ROOM_TYPE: 'storageRoomType',
    SHELF_LIFE_TYPE: 'shelfLifeType',
    EXPIRATION_DATE_THRESHOLD: 'expirationDateThresholdInDays',
    CLEARANCE_SALE_THRESHOLD: 'clearanceSaleThresholdInDays',
    UOM_TYPE: 'uomType',
    UOM_UNIT: 'uomUnit',
    UOM_LOW_RANGE: 'lowRange',
    UOM_UPPER_RANGE: 'upperRange',
    UOM_BONUS: 'bonus',
    UOM_MULTIPLIER: 'multiplier',
    UOM_SECONDARY_UNIT: 'secondaryUomUnit',
    UOM_COMPONENTS: 'uomComponents',
    IS_RETURNABLE: 'isReturnable',
    RETURNED_PRODUCT_IDS: 'returnedProductIds',
    MULTI_SELLING_VARIANT_PRODUCT_IDS: 'multiSellingVariantProductIds'
}

export const LOADED_ITEM_FIELDS = {
    IS_TAXABLE_GOODS: 'isTaxableGoods',
    TAX_RATE: 'taxRate',
    CONVERSION_TEMPLATES: 'conversionTemplates',
    ITEM_DIMENSION: 'itemDimension',
    PRODUCT_TAGS: 'productTags',
    PRODUCT_FRONTEND_LABEL_IDS: 'productFrontendLabelIds',
    IS_SELLABLE: 'isSellable',
    IS_PURCHASABLE: 'isPurchasable',
    IS_STORABLE: 'isStorable',
    IS_ACTIVE: 'isActive',
    IS_RAW_MATERIAL: 'isRawMaterial',
    IS_OT_PRODUCTION: 'isOtProduction',
    IS_NEW: 'isNew',
    NEW_LABEL_START_DATE: 'newLabelStartDate',
    NEW_LABEL_END_DATE: 'newLabelEndDate',
}

export const CONVERSION_TEMPLATE_FIELDS = {
    CONVERSION_TEMPLATE_ITEMS: 'conversionTemplateItems',
}

export const CONVERSION_TEMPLATE_ITEM_FIELDS = {
    PRODUCT: 'product',
    QUANTITY: 'quantity',
    QUANTITY_UPPER_BOUND: 'quantityUpperBound',
    QUANTITY_LOWER_BOUND: 'quantityLowerBound',
    ITEM_STAGE: 'itemStage',
}

export const CONVERSION_TEMPLATE_ITEM_PRODUCT_FIELDS = {
    SKU_ID: 'skuId',
    SELLING_UNIT: 'sellingUnit',
}

export const CONVERSION_TEMPLATE_PAYLOAD_FIELDS = {
    SKU_ID: 'skuId',
    UPPER_BOUND: 'quantityUpperBound',
    LOWER_BOUND: 'quantityLowerBound',
    QUANTITY: 'quantity',
}

export const BOOLEAN_VALUES = {
    NULL: 'NULL',
    TRUE: 'True',
    FALSE: 'False'
}

export const NUMBER_VALUES = {
    EMPTY: '',
}

export const CONVERSION_FIELDS = {
    SKU: 'sku',
    QUANTITY: 'quantity',
}

export const STATUSES = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
}

export const SPECIAL_TYPE = {
    NONE: 'NONE',
    BULKING: 'BULKING',
    BUNDLING: 'BUNDLING',
}

export const IS_TAXABLE_GOODS_STATUSES = {
    IS_TAXABLE_GOODS: 'Barang Kena Pajak (BKP)',
    NOT_TAXABLE_GOODS: 'Bukan Barang Kena Pajak (Non-BKP)',
}

export const WAREHOUSE_TAGS_PREFIX = 'wh-';

export const ITEM_STAGES = {
    INITIAL: 'initial',
    FINAL: 'final'
}

export const CHOICES = {
    YES: 'Yes',
    NO: 'No',
}

export const PRODUCT_RESTRICTION_PLATFORMS = {
    WEB: 'WEB',
    IOS: 'IOS',
    ANDROID: 'ANDROID',
}